<template>
    <LiefengContent>
        <template v-slot:title>体质检测记录</template>
        <template v-slot:toolsbarRight>
            <Form  :label-colon="true" :inline="true" class="search">
             <FormItem>
                <Input
                    :maxlength="20"
                    v-model.trim="keyword"
                    placeholder="请输入姓名/手机号进行查询"
                    style="width: 200px; margin-right: 10px"
                    ></Input>
             </FormItem>
             <Button style="margin-right:10px" type="primary" @click="searchBtn">查询</Button>
            <Button style="margin-right:10px" type="info" @click="resetBtn">重置</Button>
         </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    created() {
        this.getList()
    },
    data() {
        return {
            talbeColumns: [
                {
                    title: "姓名",
                    align: "center",
                    minWidth: 180,
                    key: "name",
                },
                {
                    title: "性别",
                    align: "center",
                    minWidth: 180,
                    key: "sex",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.sex == 1
                                ? "男"
                                : params.row.sex == 2
                                ? "女"
                                : '未知'
                        )
                    },
                },
                {
                    title: "手机号码",
                    align: "center",
                    minWidth: 180,
                    key: "mobile",
                },
                {
                    title: "身高（cm）",
                    align: "center",
                    minWidth: 180,
                    key: "height",
                },
                {
                    title: "体重（Kg）",
                    align: "center",
                    minWidth: 180,
                    key: "weight",
                },
                {
                    title: "住址",
                    align: "center",
                    minWidth: 180,
                    key: "addr",
                },
                {
                    title: "测试时间",
                    align: "center",
                    minWidth: 180,
                    key: "gmtCreate",
                    render: (h,params) =>{
                        return h('div',{}, this.$core.formatDate(new Date(params.row.gmtCreate || ""), "yyyy-MM-dd hh:mm:ss"))
                    }
                },
                {
                    title: "测试结果",
                    align: "center",
                    minWidth: 180,
                    key: "result2",
                },
                {
                    title: "倾向体质",
                    align: "center",
                    minWidth: 180,
                    key: "result1",
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,
            keyword:''
        }
    },
    methods: {
        searchBtn(){
            this.page = 1
            this.getList()
        },
        resetBtn(){
            this.keyword = ''
            this.searchBtn()
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList() {
            this.loading = true
            try {
                this.$get("/gateway/api/sycomponent/pc/health/listAllArchiveByPage", {
                    keyword: this.keyword,
                    category: "",
                    orgCode: "QYJG0353",
                    oemCode: "zjsm",
                    page: this.page,
                    pageSize: this.pageSize,
                }).then(res => {
                    this.loading = false
                    if (res.code && res.dataList && res.dataList.length) {
                        this.tableData = res.dataList
                        res.dataList.map(item =>{
                            if(item.accessResult && item.accessResult != ''){
                                item.result = JSON.parse(item.accessResult)
                            }
                            if(item.result){
                                if(item.result[0] && item.result[0].length){
                                    item.result1 = item.result[0].join(',')
                                }
                                if(item.result[1] && item.result[1].length){
                                    item.result2 = item.result[1].join(',')
                                }
                            }
                        })
                        this.total = res.maxCount
                    }
                })
            } catch (err) {
                this.loading = false
                this.$Message.error({
                    content: "获取数据失败",
                    background: true,
                })
                return
            }
        },
    },
}
</script>

<style>
</style>